import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from './components/Spinner';
import PrivateRoute from './components/PrivateRoute';
import Login from './views/Login';

const Home = lazy(() => import('./views/Home'));
const KolskiNalozi = lazy(() => import('./views/KolskiNalozi'));
const Nalog = lazy(() => import('./views/Nalog'));
const Vozila = lazy(() => import('./views/Vozila'));
const Vozilo = lazy(() => import('./views/Vozilo'));
const Servis = lazy(() => import('./views/Servis'));

const LazyComponent = ({ component: Component, props }) => {
  return (
    <Suspense fallback={<Spinner />}>
      <Component {...props} />
    </Suspense>
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <PrivateRoute
        exact
        path="/"
        component={() => <LazyComponent component={Home} />}
      />
      <PrivateRoute
        exact
        path="/kolski-nalozi"
        component={() => <LazyComponent component={KolskiNalozi} />}
      />
      <PrivateRoute
        exact
        path="/kolski-nalog-detaljno/:id"
        component={() => <LazyComponent component={Nalog} />}
      />
      <PrivateRoute
        exact
        path="/servis-detaljno/:id"
        component={() => <LazyComponent component={Servis} />}
      />

      <PrivateRoute
        exact
        path="/vozila"
        component={() => <LazyComponent component={Vozila} />}
      />

      <PrivateRoute
        exact
        path="/vozila/:id"
        component={() => <LazyComponent component={Vozilo} />}
      />

      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default Routes;
