import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Layout, Menu, Button } from 'antd';
import {
  CarOutlined,
  HomeOutlined,
  AuditOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { StyledMenu } from './style';
import Logo from '../Logo';
import { useAuthContext } from '../../context/AuthContext';
import { useAbilityContext } from '../../context/AbilityContext';

const { Header } = Layout;

const Navbar = () => {
  const location = useLocation();
  const history = useHistory();
  const { doSignOut } = useAuthContext();
  const ability = useAbilityContext();

  const logOut = () => {
    doSignOut();
  };

  return (
    <Header>
      <StyledMenu
        onSelect={({ key }) => {
          if (key !== 'odjava') history.push(key);
        }}
        selectedKeys={[location.pathname]}
        theme="dark"
        mode="horizontal"
      >
        <Logo />
        <Menu.Item key="/">
          <HomeOutlined />
          <span>Home</span>
        </Menu.Item>
        <Menu.Item key="/kolski-nalozi">
          <AuditOutlined />
          <span>Nalozi</span>
        </Menu.Item>
        {ability && ability.can('view', 'vozila') && (
          <Menu.Item key="/vozila">
            <CarOutlined />
            <span>Vozila</span>
          </Menu.Item>
        )}
        {ability && ability.can('view', 'mpos') && (
          <Menu.Item
            key="/mpos"
            onClick={() => {
              window.location.href = 'https://mci-mpos.netlify.app/';
            }}
          >
            <ShopOutlined />
            <span>MPOS</span>
          </Menu.Item>
        )}
        <Menu.Item style={{ float: 'right' }} key="odjava">
          <Button type="link" onClick={logOut}>
            Odjavi se
          </Button>
        </Menu.Item>
      </StyledMenu>
    </Header>
  );
};

export default Navbar;
