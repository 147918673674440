import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import logo from './logo.png';
import { Input, Button, Alert, Form, Image } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../context/AuthContext';

const BaseSignInForm = () => {
  let location = useLocation();

  const {
    doSignInWithEmailAndPassword,
    redirectToReferrer,
    loading,
    error,
    setError,
  } = useAuthContext();

  const onFinish = (values) => {
    doSignInWithEmailAndPassword(values.email, values.password);
  };
  const { from } = location.state || { from: { pathname: '/kolski-nalozi' } };
  if (redirectToReferrer) return <Redirect to={from} />;

  return (
    <div style={{ height: '100%', minHeight: '100vh' }}>
      <div
        style={{
          width: '100%',
          maxWidth: '800px',
          padding: '0 20px 0',
          paddingTop: '20vh',
          margin: 'auto',
        }}
      >
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Image
            src={logo}
            preview={false}
            style={{
              filter: ' hue-rotate(10deg)',
            }}
          />
        </div>
        <Form onFinish={onFinish}>
          {error && (
            <Alert
              message="Pogrešan E-mail ili Password"
              type="error"
              showIcon
              closable
              onClose={() => setError(null)}
            />
          )}
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'E-mail nije validan!',
              },
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="E-mail"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rule={[
              {
                required: true,
                type: 'password',
                message: 'Molimo unesite password!',
              },
            ]}
          >
            <Input
              type="password"
              size="large"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item colon={false}>
            <Button
              loading={loading}
              type="primary"
              size="large"
              htmlType="submit"
              className="login-form-button"
              style={{ width: '100%' }}
            >
              Prijavi se
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default BaseSignInForm;
