import React, { createContext, useContext, useState } from 'react';
import useSessionStorage from '../components/useSessionStorage';

export const Context = createContext({});

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;

  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);

  const toggleModal = () => setShowModal((s) => !s);

  const [paginationStorage, setPaginationStorage] = useSessionStorage(
    'paginationNalozi',
  );
  const [pagination, setPaginationState] = useState(paginationStorage);
  const setPagination = (value) => {
    setPaginationState(value);
    setPaginationStorage(value);
  };

  const [filterStorage, setFilterStorage] = useSessionStorage('filterVozila');
  const [filter, setFilterState] = useState(filterStorage);
  const setFilter = (value) => {
    setFilterState(value);
    setFilterStorage(value);
  };

  // Make the context object:
  const context = {
    showModal,
    toggleModal,
    action,
    setAction,
    editingRecord,
    setEditingRecord,
    pagination,
    setPagination,
    filter,
    setFilter,
  };

  // pass the value in provider and return
  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useVoziloContext = () => useContext(Context);
