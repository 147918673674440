import { Ability, AbilityBuilder } from '@casl/ability';

const subjectName = (item) => {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
};

export const defineRulesFor = (auth) => {
  const { can, rules } = new AbilityBuilder();
  if (auth.role === 'admin') {
    can('view', 'vozila');
    can('create', 'nalog');
    can('edit', 'nalog');
    can('view', 'filteri');
  }

  if (auth.role === 'driver') {
    can('edit', 'kilometraza');
  }

  if (process.env.CLIENT === 'mci') {
    can('view', 'mpos');
  }

  /*
  if (auth.role !== 'driver') {
    can('view', 'tasks');
  }
  if (auth.role !== 'driver' && auth.role !== 'placer') {
    can('view', 'reports');
  } */
  return rules;
};

export const ability = new Ability([], { subjectName });
